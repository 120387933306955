import { Box, Flex, Grid, Image, Card, CardBody, CardFooter,Stack, Text, Button, CardHeader, Center } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';

import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';

import { BrowserProvider, Contract, formatUnits, WebSocketProvider, parseUnits, parseEther } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';


import Swal from 'sweetalert2';
import '../Styles/Home.css';


const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};


const nftContractAddress = '0x8722446616b21539E6CD41C461F186d3EF045477';


const NFTHead = () => {

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [HasClaimed, setHasClaimed] = useState();
  const [ClaimableTokens, setClaimbale] = useState("Please Connect Wallet");
 
  

  useEffect(() => {
    viewHasClaimed();
    viewClaimable();
}, [address])


//------------START VIEWABLES------------------



    async function viewClaimable() {
      if (typeof window.ethereum !== 'undefined') {
      let ethersProvider;
      ethersProvider = new BrowserProvider(walletProvider)
      const signer = await ethersProvider.getSigner()
      const contract = new Contract(nftContractAddress, LionNft.abi, signer);
      try {
          const Amount = await contract.viewClaimableAmount();
          const StringAmount = Amount.toString();
          const ethAmount = formatUnits(StringAmount,18);

          setClaimbale("Claimable Tokens: " + ethAmount);
  
          console.log("Amount",ethAmount);
        
      } catch (err) {
          console.log(err.message);
      
      }
    }
  }
  


   





        async function viewHasClaimed() {
          if (typeof window.ethereum !== 'undefined') {
            const ethersProvider = new BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()
            const contract = new Contract(nftContractAddress, LionNft.abi, signer);
            try {
             
              
                const HasClaimed = await contract.hasClaimed(address);
             
                setHasClaimed("Your Claimed Status: " + HasClaimed);
           
               
                console.log("Has Claimed",HasClaimed);
              
            } catch (err) {
              console.log(err.message);
            }
          }
        }



//------------END VIEWABLES------------------





//------------START CALLABLES------------------

        const Claim = async () => {
          try {
            if (!isConnected) throw Error('User disconnected')
            const ethersProvider = new BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()
           
            const contract = new Contract(nftContractAddress, LionNft.abi, signer);
   
            const transaction = await contract.claimTokens();
           
        
            await transaction.wait( Swal.fire(swalOptions));
          
            Swal.fire({
              icon: 'success',
              title: 'Transaction Successful',
              text: 'Tokens Claimed Successful!',
            })
                
              
          } catch (error) {
            console.error('Transaction failed:', error);
        
            Swal.fire({
              icon: 'error',
              title: 'Transaction Failed',
              text: error.reason,
            });
          }
        };
    


//------------END CALLABLES------------------

       
       





return (

  <Box mb="100px">
            <Box>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                  

                    <Card className='Btns'    textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxthead'>
                     ChatterShield</CardHeader>
                   
                     <CardHeader className='cardtxthead'>
                     Claim Migration Tokens</CardHeader>

                       
                        <br></br>
                       <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="20px">
                        <Card className='NFTHead'   textAlign="center"   borderRadius="30px"  fontSize="30px" fontWeight="bold">
                   
                        <CardHeader className='cardtxt'>
                         How To Claim
                        
                        </CardHeader>
                      
                    <Text color="whiteAlpha.800" >
                    Connect your wallet to the ChatterShield Dapp, and click the "Claim Tokens" button to claim your migration tokens. <br></br> <br></br>If you have already claimed your tokens, the claimed status will be true. <br></br> <br></br> If you have any issues claiming your tokens, please contact the ChatterShield team.









                    </Text>
                    <br>
                        </br>
                        
                        <br>
                        </br>

                       

                        <Text color="whiteAlpha.800" >{}</Text>

                     
                     </Card>


                     <Card className='NFTHead' textAlign="center"  borderRadius="30px" fontSize="30px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                         Your Wallet
                        </CardHeader>
                        <Text color="whiteAlpha.800" >{HasClaimed}</Text>
                    <br></br>
                        <Text color="whiteAlpha.800" >{ClaimableTokens}</Text>

                      
                       
                        <br></br>
                
                        <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => Claim()}>
                          <font><b>Claim Tokens</b></font>
                        </Button>

                       
                      </Card>
                      <br></br>
                     
                     </Grid>
                    

                     <br>
                        </br>
                      

                  </Card>
                  </Grid>
              
             </Box>
            <br></br>
          
        </Box>
        
    );
};
export default NFTHead;